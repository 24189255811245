<template>
    <div class="home-wrapper">
        <div class="new-home-section hero">
            <div class="section-content">
                <div class="slider-carousel">
                    <carousel :per-page="1" :autoplay="true" :paginationEnabled="false" :navigationEnabled="true" :loop="true" :autoplayTimeout="5000">
                        <slide>
                            <div class="left-side text-side">
                                <div class="text-wrapper">
                                    <h2>Amazing software tools for commercial boat owners, skippers and crew</h2>
                                    <h5>Digital logs, safety management systems, operations manuals and more, with free support every step of the way.</h5>
                                    <h3 class="price is-warning">Maximum compliance, zero paper</h3>
                                    <div class="spacer s10"></div>
                                    <div class="buttons">
                                        <button class="button is-medium is-info" @click="openEnquiry = true">Make an Enquiry</button>
                                        <router-link class="button is-medium is-primary" to="book-a-demo">Book a Free Demo</router-link>
                                    </div>
                                </div>
                            </div>
                            <div class="right-side">
                                <!-- <img src="@/assets/devices.png"> -->
                                <ImageItem class="article-item__image" :source="require(`../assets/img/devices2.png`)" alt="Amazing software tools" />
                            </div>
                        </slide>

                        <slide>
                            <div class="left-side text-side">
                                <div class="text-wrapper">
                                    <h2>The ultimate app for commercial vessels</h2>
                                    <h5>Download the OffshoreSMS app and start logging startups checks, maintenance, incidents and more!</h5>
                                    <h3 class="price is-warning">Now 100% FREE!</h3>
                                    <div class="spacer s10"></div>
                                    <div class="buttons">
                                        <span class="button is-medium is-success" @click="trackLink('sign-up', 'signup')">Create a FREE Profile</span>
                                        <span class="button is-medium is-light" @click="trackLink('sign-in', 'login')">Member Log in</span>
                                    </div>
                                </div>
                            </div>
                            <div class="right-side">
                                <ImageItem class="article-item__image" :source="require(`../assets/img/apps2.png`)" alt="Say goodbye to paper" />
                            </div>
                        </slide>

                        <slide>
                            <div class="left-side text-side">
                                <div class="text-wrapper">
                                    <h2>Try our incredible Fleet Management Dashboard for multiple vessels and crew</h2>
                                    <h5>Manage all of your vessels, skippers and crew from one real-time dashboard</h5>
                                    <h3 class="price is-warning">Perfect for all fleets!</h3>
                                    <div class="spacer s10"></div>
                                    <div class="buttons">
                                        <router-link class="button is-medium is-success" to="plan-fleet">Find out more</router-link>
                                    </div>
                                </div>
                            </div>
                            <div class="right-side">
                                <ImageItem class="article-item__image" :source="require(`../assets/img/fleet-screens.png`)" alt="Fleet Management" />
                            </div>
                        </slide>

                        <slide>
                            <div class="left-side text-side">
                                <div class="text-wrapper">
                                    <h2>Get a safety management system for your vessel in just a few clicks</h2>
                                    <h5>Build your own SMS in our app, or send us your existing SMS and we can set it up for you</h5>
                                    <h3 class="price is-warning">It's never been easier to get compliant</h3>
                                    <div class="spacer s10"></div>
                                    <div class="buttons">
                                        <router-link class="button is-medium is-info" to="plan-logs-sms">Find out more</router-link>
                                    </div>
                                </div>
                            </div>
                            <div class="right-side">
                                <ImageItem class="article-item__image" :source="require(`../assets/img/ian-demo.png`)" alt="Fully compliant SMS" />
                            </div>
                        </slide>

                        <slide>
                            <div class="left-side text-side">
                                <div class="text-wrapper">
                                    <h2>Digital solutions for any commercial vessel</h2>
                                    <h5>We can turn your paper SMS and logbooks into digital so you can access them from anywhere!</h5>
                                    <h3 class="price is-warning">Call us to find out more</h3>
                                    <div class="spacer s10"></div>
                                    <div class="buttons">
                                        <router-link class="button is-medium is-info" to="feedback">Contact Us</router-link>
                                    </div>
                                </div>
                            </div>
                            <div class="right-side">
                                <ImageItem class="article-item__image" :source="require(`../assets/img/device-in-hand.png`)" alt="Fully compliant SMS" />
                            </div>
                        </slide>
                    </carousel>
                </div>
            </div>
        </div>

        <div class="new-home-section">
            <div class="hero-intro">
                <h1><font-awesome-icon icon="quote-right" />&nbsp;We've made it really easy to go paperless on any commercial vessel!</h1>
                <h2>Build your safety management system and checklists yourself, or let us do it for you.</h2>
            </div>
        </div>

        <div class="spacer s30"></div>

        <div class="new-home-section centered">
            <div class="tab-wrapper">
                <div :class="{ selected: worksTab == '1' }" @click="worksTab = '1'" class="round-tab">
                    <span class="icon"><font-awesome-icon icon="user"/></span>
                    <span class="text">I'm an owner operator</span>
                </div>

                <div :class="{ selected: worksTab == '2' }" @click="worksTab = '2'" class="round-tab">
                    <span class="icon"><font-awesome-icon icon="ship"/></span>
                    <span class="text">I Manage a Fleet</span>
                </div>
            </div>

            <div class="tab-content-wrapper one" v-if="worksTab == '1'">
                <div class="tc-image">
                    <img class="db-hero-image" src="@/assets/img/apps2.png" alt="Devices on OffshoreSMS" />
                </div>
                <div class="tc-content">
                    <h4>You can start for FREE!</h4>
                    <div class="home-steps-list">
                        <div class="step-item">
                            <div class="counter"><span>1</span></div>
                            <div class="details">
                                Download the OffshoreSMS app and create a FREE user profile (it takes just 2 minutes).
                            </div>
                        </div>
                        <div class="step-item">
                            <div class="counter"><span>2</span></div>
                            <div class="details">
                                Add your vessel for FREE and you are good to go - basic logs in the app are free forever!
                            </div>
                        </div>
                        <div class="step-item">
                            <div class="counter"><span>3</span></div>
                            <div class="details">
                                If you need a safety management system, activate a FREE 7 day trial of our paid subscription.
                            </div>
                        </div>
                        <div class="step-item">
                            <div class="counter"><span>4</span></div>
                            <div class="details">
                                If you love it, (and we know you will), subscribe for less than $3/week (includes Free support)!
                            </div>
                        </div>
                    </div>

                    <div class="spacer s20"></div>
                    <div class="download mini">
                        <a href="https://apps.apple.com/us/app/offshoresms/id1526656158" target="_blank" rel="noreferrer">
                            <ImageItem class="article-item__image" :source="require(`../assets/img/app-store.png`)" alt="OffshoreSMS app on the Apple App Store" />
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=offshoresms.appdev.nybble.com.smsapp" target="_blank" rel="noreferrer">
                            <ImageItem class="article-item__image" :source="require(`../assets/img/play-store.png`)" alt="OffshoreSMS app on the Google Play Store" />
                        </a>
                    </div>
                </div>
            </div>

            <div class="tab-content-wrapper two" v-if="worksTab == '2'">
                <div class="tc-image">
                    <img class="db-hero-image" src="@/assets/img/apps3.png" alt="Devices on OffshoreSMS" />
                </div>
                <div class="tc-content">
                    <h4>Manage your vessels in one place!</h4>
                    <div class="home-steps-list">
                        <div class="step-item">
                            <div class="counter"><span>1</span></div>
                            <div class="details">
                                Create a Free user profile on OffshoreSMS from the link below - every user has their own profile.
                            </div>
                        </div>
                        <div class="step-item">
                            <div class="counter"><span>2</span></div>
                            <div class="details">
                                Add a new Fleet from your Dashboard (totally free) - you can add multiple admins to each fleet.
                            </div>
                        </div>
                        <div class="step-item">
                            <div class="counter"><span>3</span></div>
                            <div class="details">
                                In your new Fleet Dashboard, add as many vessels as you like (still free), and activate a Free 7 Day Trial on any vessel.
                            </div>
                        </div>
                        <div class="step-item">
                            <div class="counter"><span>4</span></div>
                            <div class="details">
                                Get your skippers and crew to download the app and create their personal profiles (100% free for unlimited crew), then add them to each vessel.
                            </div>
                        </div>
                        <div class="step-item">
                            <div class="counter"><span>5</span></div>
                            <div class="details">
                                When you're happy (you will be), subscribe online or give us a call to discuss multi-vessel fleet options. Remember you only pay for vessels, not people.
                            </div>
                        </div>
                    </div>
                    <div class="buttons">
                        <span class="button is-primary" @click="trackLink('sign-up', 'signup')">Get Started</span>
                        <!-- <button class="button blue" @click="">Fleet Features</button> -->
                        <router-link to="plan-fleet" class="button blue">Fleet Features</router-link>
                    </div>
                </div>
            </div>
        </div>

        <div class="new-home-section">
            <div class="section-content centered">
                <h3 class="is-primary">View our Vessel Subscription options</h3>
                <h5>Whether you've got one boat or 50, are a commercial fisher or manage a huge fleet Offshore, we have a solution for you.</h5>
                <h5 class="bold">Browse our plans below:</h5>
                <div class="spacer s30"></div>

                <div class="pricing-wrapper">
                    <div class="pricing-table centered">
                        <div class="pricing-plan is-blue">
                            <div class="plan-header">Digital Ship's Log</div>
                            <div class="plan-icon"><font-awesome-icon icon="mobile-alt" /></div>
                            <div class="plan-price">
                                <span class="plan-price-amount"><span class="plan-price-currency"></span>100% FREE</span>
                            </div>
                            <div class="plan-items">
                                <div class="plan-item">Unlimited digital Ship's Logs in the app</div>
                                <div class="plan-item">Create a free vessel profile on OffshoreSMS</div>
                                <div class="plan-item">Join a global network of commercial mariners</div>
                                <div class="plan-item">Includes a digital spec sheet to share with anyone</div>
                                <div class="plan-item">Take the first step towards zero-paper!</div>
                            </div>
                            <div class="plan-footer">
                                <router-link to="plan-logs" class="button is-fullwidth">All Features</router-link>
                            </div>
                        </div>

                        <div class="pricing-plan is-primary">
                            <div class="plan-header">Digital SMS</div>
                            <div class="plan-icon"><font-awesome-icon icon="clipboard-list" /></div>
                            <div class="plan-price">
                                <span class="plan-price-amount"><span class="plan-price-currency">$</span>149</span> <span class="price-rate">per asset<br />per year</span>
                            </div>
                            <div class="plan-items">
                                <div class="plan-item">Build a digital safety management system</div>
                                <div class="plan-item">Includes the awesome digital Ship's Log</div>
                                <div class="plan-item">Also includes a calendar for important dates</div>
                                <div class="plan-item">Plus free online support for a year</div>
                                <div class="plan-item">Add a <span class="green bold">drop-in SMS for just $300</span></div>
                            </div>
                            <div class="plan-footer">
                                <router-link to="plan-logs-sms" class="button is-fullwidth">All Features</router-link>
                            </div>
                        </div>

                        <div class="pricing-plan is-skip">
                            <div class="plan-header">Pro Plan</div>
                            <div class="plan-icon"><font-awesome-icon icon="check-square" /></div>
                            <div class="plan-price">
                                <span class="plan-price-amount"><span class="plan-price-currency">$</span>249</span> <span class="price-rate">per asset<br />per year</span>
                            </div>
                            <div class="plan-items">
                                <div class="plan-item">All crew and asset management tools</div>
                                <div class="plan-item">Safety management system with helpers</div>
                                <div class="plan-item">Full inventory and maintenance solution</div>
                                <div class="plan-item">Includes the powerful custom logs feature</div>
                                <div class="plan-item">Plus team messaging with task allocation</div>
                            </div>
                            <div class="plan-footer">
                                <router-link to="plan-pro" class="button is-fullwidth">All Features</router-link>
                            </div>
                        </div>
                    </div>
                    <div class="clear"></div>
                </div>

                <div class="spacer s70"></div>

                <!-- <hr /> -->
            </div>
        </div>

        <div class="full-width-image section1">
            <div class="hero-content">
                <span class="hero-icon"><font-awesome-icon icon="coffee"/></span>
                <span class="hero-text">Did you know that we offer a range of additional services...</span>
            </div>
        </div>

        <div class="new-home-section">
            <div class="section-content centered mt-6">
                <h3 class="is-primary">View our other Services</h3>
                <h5>
                    Change is hard, and migrating your skippers and crews from traditional paper-based logbooks and SMSs is a challenge, but we're here to help. Whether you're preparing for a
                    compliance audit, building a safety management system for the first time, or looking to make the switch to zero-paper safety and compliance, we have a service that will work for
                    you.
                </h5>
                <!-- <h5 class="bold">Browse our plans below:</h5> -->
                <div class="spacer s50"></div>

                <div class="pricing-wrapper">
                    <div class="pricing-table centered mb-0">
                        <div v-for="(service, idx) in firstServices" :key="`service-${idx}`" class="pricing-plan" :class="[service.theme]">
                            <div class="plan-header">{{ service.title }}</div>
                            <div class="plan-icon"><font-awesome-icon :icon="service.icon" /></div>
                            <div class="plan-price">
                                <span class="plan-price-amount">
                                    <span v-if="service.pricePrefix != ''" class="plan-price-currency">{{ service.pricePrefix }}</span>
                                    {{ service.price }}
                                </span>
                                <span v-if="service.priceSuffix != ''" class="price-rate">{{ service.priceSuffix }}</span>
                            </div>
                            <div class="plan-items">
                                <div class="plan-item" v-for="(item, inx) in service.introItems" :key="`intro-${inx}`">{{ item }}</div>
                            </div>
                            <div class="plan-footer">
                                <span @click="learnMore(service)" class="button is-fullwidth">Learn More</span>
                            </div>
                        </div>
                    </div>
                    <div class="clear"></div>
                </div>

                <div class="spacer s60"></div>

                <div class="pricing-wrapper">
                    <div class="pricing-table centered mb-0">
                        <div v-for="(service, idx) in secondServices" :key="`service-${idx}`" class="pricing-plan" :class="[service.theme]">
                            <div class="plan-header">{{ service.title }}</div>
                            <div class="plan-icon"><font-awesome-icon :icon="service.icon" /></div>
                            <div class="plan-price">
                                <span class="plan-price-amount">
                                    <span v-if="service.pricePrefix != ''" class="plan-price-currency">{{ service.pricePrefix }}</span>
                                    {{ service.price }}
                                </span>
                                <span v-if="service.priceSuffix != ''" class="price-rate">{{ service.priceSuffix }}</span>
                            </div>
                            <div class="plan-items">
                                <div class="plan-item" v-for="(item, inx) in service.introItems" :key="`intro-${inx}`">{{ item }}</div>
                            </div>
                            <div class="plan-footer">
                                <span @click="learnMore(service)" class="button is-fullwidth">Learn More</span>
                            </div>
                        </div>
                    </div>
                    <div class="clear"></div>
                </div>

                <div class="spacer s30"></div>

                <hr />
            </div>
        </div>

        <!-- <div class="new-home-section">
            <div class="section-content">
                <div class="hero has-text-centered">
                    <p class="title is-3 is-dark">Enterprise Fleet Features</p>
                    <p class="subtitle is-5 mt-0">
                        We're about to launch a new Enterprise suite of features designed to give commercial marine fleet managers the tools they need to maintain best-practise compliance and
                        reporting across their marine assets and teams.
                    </p>
                </div>
                <div class="spacer s40"></div>

                <div class="left-side text-side">
                    <div class="pricing-wrapper fleet">
                        <div class="pricing-table">
                            <div class="pricing-plan is-dark">
                                <div class="plan-header is-dark">Currently in Beta</div>
                                <div class="plan-price">
                                    <span class="plan-price-amount"><span class="plan-price-currency">From $</span><span class="strikethrough">550</span></span>
                                    <span class="price-rate">per fleet<br />per year</span>
                                </div>
                                <div class="plan-items">
                                    <div class="plan-item">Access advanced reporting<br />in the web dashboard</div>
                                    <div class="plan-item">Connect suppliers to<br />real time maintenance</div>
                                    <div class="plan-item">Fleet level custom logs<br />and custom log reports</div>
                                    <div class="plan-item">No limits on uploads<br />SMS sharing</div>
                                    <div class="plan-item">Connect to our new<br />fleet API library</div>
                                </div>
                                <div class="plan-footer">
                                    <router-link to="plan-fleet" class="button is-fullwidth">See All Features</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="right-side">
                    <ImageItem class="article-item__image" :source="require(`../assets/img/fleet-screens.png`)" alt="Fleet Dashboard" />
                </div>
                <div class="clear"></div>
            </div>
        </div> -->

        <div class="new-home-section centered">
            <div class="spacer s50"></div>
            <div class="download">
                <a href="https://apps.apple.com/us/app/offshoresms/id1526656158" target="_blank" rel="noreferrer">
                    <!-- <img class="partners" src="@/assets/img/app-store.png" /> -->
                    <ImageItem class="article-item__image" :source="require(`../assets/img/app-store.png`)" alt="OffshoreSMS app on the Apple App Store" />
                </a>
                <a href="https://play.google.com/store/apps/details?id=offshoresms.appdev.nybble.com.smsapp" target="_blank" rel="noreferrer">
                    <!-- <img class="partners" src="@/assets/img/play-store.png" /> -->
                    <ImageItem class="article-item__image" :source="require(`../assets/img/play-store.png`)" alt="OffshoreSMS app on the Google Play Store" />
                </a>
            </div>
        </div>

        <div class="new-home-section">
            <div class="section-content centered">
                <hr />
                <div class="spacer s30"></div>
                <h3>Bring your crew and skippers together in real time</h3>
                <h5>
                    Connect your crew and skippers to your digital safety management system at the click of a button, and let your skippers induct new crew and passengers, do unlimited checklists and
                    logs, timed activities and more.
                </h5>
                <div class="spacer s30"></div>
                <div class="centered">
                    <div class="buttons centered test-activity">
                        <span class="button is-info is-medium" @click="addNextActivity()" to="pricing">Simulate an Activity Log</span>
                    </div>

                    <div class="imac-wrapper">
                        <ImageItem class="article-item__image" :source="require(`../assets/img/imac-bg.png`)" alt="Live Activity Feed Simulator" />

                        <div class="screen-bg">
                            <div class="screen-content">
                                <h3><font-awesome-icon icon="list" /> &nbsp; Live Activity Feed</h3>
                                <div v-if="randomActivities.length">
                                    <ul class="revisions">
                                        <li v-for="(item, index) in randomActivities.slice(0, 9)" :key="index">{{ item.title }}</li>
                                    </ul>
                                </div>
                                <div v-else>
                                    <p>No activity at the moment.</p>
                                </div>
                            </div>
                        </div>
                        <div class="clear"></div>
                    </div>

                    <div class="static-image">
                        <!-- <img class="imac-bg" src="@/assets/img/home-fleet-db.jpg"> -->
                        <ImageItem class="article-item__image" :source="require(`../assets/img/home-fleet-db.jpg`)" alt="Live Activity Feed" />
                    </div>
                </div>
            </div>
        </div>

        <div class="new-home-section newsletter">
            <div v-if="!savingLead" class="centered">
                <h5>Follow our monthly updates with news and new features!</h5>
                <input type="email" maxlength="100" placeholder="Enter your email address..." @input="showField = true" v-model="newSignup.email" />
            </div>
            <h5 class="centered" v-else>{{ thankYou }}</h5>
            <div v-if="showField && !savingLead" class="buttonsX centered">
                <button :disabled="!emailValid" class="button is-medium is-skip" @click="saveLead()">Keep me in the loop</button>
            </div>
            <div class="spacer s40"></div>
            <hr />
        </div>

        <div class="new-home-section">
            <div class="section-content centered">
                <div class="cloud"><font-awesome-icon icon="cloud" />&nbsp;<font-awesome-icon icon="envelope" /></div>
                <div class="spacer s20"></div>
                <h3 class="green">Introducing New Team Messaging!</h3>
                <h5>We've just launched our newest and most anticipated feature - real time in-app messaging for PRO Plan vessel teams!</h5>
                <h5>
                    Send an in-app message (and optional email) to one or more of the crew on any of your PRO vessels, and get timestamped read receipts plus set up to 5 one-click replies, all in real
                    time!
                </h5>
                <h5 class="bold">Included in any Fleet or PRO Plan subscription</h5>
                <div class="spacer s30"></div>
                <div class="centered">
                    <div class="buttons centered">
                        <span class="button is-medium is-success" @click="trackLink('sign-up', 'signup')">Create a FREE Profile</span>
                        <router-link class="button is-warning is-medium" to="pricing">View Our Pricing</router-link>
                    </div>
                </div>

                <div class="spacer s70"></div>

                <hr />
            </div>
        </div>

        <div class="new-home-section">
            <div class="section-content">
                <div class="left-side text-side">
                    <h2>We've built the best commercial vessel management tools</h2>
                    <h5>Our real time Fleet Dashboard and mobile apps are the best way to manage your commercial vessel fleet.</h5>
                </div>
                <div class="right-side">
                    <!-- <img src="@/assets/img/fleet-db.jpg"> -->
                    <ImageItem class="article-item__image" :source="require(`../assets/img/fleet-db.jpg`)" alt="Fleet Management Tools" />
                </div>
                <div class="clear"></div>
            </div>
        </div>

        <div class="new-home-section">
            <div class="section-content">
                <hr />
            </div>
        </div>

        <div class="new-home-section">
            <div class="section-content">
                <div class="right-side text-side">
                    <h2>And our mobile app is the easiest way to get a vessel SMS</h2>
                    <h5>Seriously, there is nothing like Offshore SMS to get your safety management system up and running in minutes.</h5>
                    <div class="spacer s30"></div>
                    <div class="buttons">
                        <router-link class="button is-medium is-info" to="sign-up"><font-awesome-icon icon="thumbs-up" /> &nbsp;Let's do it</router-link>
                    </div>
                </div>
                <div class="left-side">
                    <!-- <img src="@/assets/img/apps2.jpg"> -->
                    <ImageItem class="article-item__image" :source="require(`../assets/img/apps2.jpg`)" alt="Awesome Mobile Apps" />
                </div>
                <div class="clear"></div>
            </div>
        </div>

        <div class="new-home-section">
            <div class="section-content">
                <hr />
            </div>
        </div>

        <div class="new-home-section">
            <div class="section-content">
                <div class="left-side text-side">
                    <h2>We'll even give you unlimited FREE digital logs in the app!</h2>
                    <h5>
                        No credit card or any payment required - simply create a free account and add as many vessels as you like - each one has 100% Free access to a range of digital logs in the app.
                    </h5>
                </div>
                <div class="right-side">
                    <!-- <img src="@/assets/img/trial2.gif"> -->
                    <ImageItem class="article-item__image" :source="require(`../assets/img/slider-4.png`)" alt="Unlimited Free Trials" />
                </div>
                <div class="clear"></div>
            </div>
        </div>

        <div class="new-home-section">
            <div class="section-content">
                <hr />
            </div>
        </div>

        <div class="new-home-section">
            <div class="section-content">
                <div class="right-side text-side">
                    <h2>And 100% free support along the way</h2>
                    <h5>Give us a call or send a text any time to <a href="tel:+61-447-139-382">+61 447 139 382</a>. We're based in Australia and we're here to help.</h5>
                </div>
                <div class="left-side">
                    <!-- <img src="@/assets/img/handshake.jpg"> -->
                    <ImageItem class="article-item__image" :source="require(`../assets/img/handshake.jpg`)" alt="Free Support" />
                </div>
                <div class="clear"></div>
            </div>
        </div>

        <div class="new-home-section">
            <div class="section-content">
                <hr />
            </div>
        </div>

        <div class="new-home-section">
            <div class="section-content centered">
                <h3>Start doing amazing things...</h3>
                <h5>
                    Take your vessel management to the next level with these real-time safety and productivity tools that will save you money and build a better safety culture in your business, all
                    with zero paper!
                </h5>
                <div class="spacer s30"></div>
                <div class="centered">
                    <ul class="icon-list">
                        <li>
                            <div class="small-icon">
                                <!-- <img src="@/assets/icons/alert.jpg"> -->
                                <ImageItem class="article-item__image" :source="require(`../assets/icons/alert.jpg`)" alt="Live activity feed" />
                            </div>
                            <div class="details">Live activity feed from every vessel</div>
                            <div class="clear"></div>
                        </li>
                        <li>
                            <div class="small-icon">
                                <!-- <img src="@/assets/icons/template.jpg"> -->
                                <ImageItem class="article-item__image" :source="require(`../assets/icons/template.jpg`)" alt="Schedule Drills" />
                            </div>
                            <div class="details">Schedule Drills, Inspections, Maintenance and more</div>
                            <div class="clear"></div>
                        </li>
                        <li>
                            <div class="small-icon">
                                <!-- <img src="@/assets/icons/language.jpg"> -->
                                <ImageItem class="article-item__image" :source="require(`../assets/icons/language.jpg`)" alt="Connect with crew" />
                            </div>
                            <div class="details">Connect with skippers and crew in real time</div>
                            <div class="clear"></div>
                        </li>
                        <li>
                            <div class="small-icon">
                                <!-- <img src="@/assets/icons/target.jpg"> -->
                                <ImageItem class="article-item__image" :source="require(`../assets/icons/target.jpg`)" alt="PDF reports" />
                            </div>
                            <div class="details">Print out PDF reports for all vessel activity</div>
                            <div class="clear"></div>
                        </li>
                        <li>
                            <div class="small-icon">
                                <!-- <img src="@/assets/icons/lifering-2.jpg"> -->
                                <ImageItem class="article-item__image" :source="require(`../assets/icons/lifering-2.jpg`)" alt="Equipment register" />
                            </div>
                            <div class="details">View all expired equipment across the fleet</div>
                            <div class="clear"></div>
                        </li>
                        <li>
                            <div class="small-icon">
                                <!-- <img src="@/assets/icons/stopwatch.jpg"> -->
                                <ImageItem class="article-item__image" :source="require(`../assets/icons/stopwatch.jpg`)" alt="Timed Activities" />
                            </div>
                            <div class="details">New Timed Activities can record any activity on any vessel</div>
                            <div class="clear"></div>
                        </li>
                        <li>
                            <div class="small-icon">
                                <!-- <img src="@/assets/icons/checklist.jpg"> -->
                                <ImageItem class="article-item__image" :source="require(`../assets/icons/checklist.jpg`)" alt="Passenger manifests" />
                            </div>
                            <div class="details">Create passenger manifests and log head counts in the app</div>
                            <div class="clear"></div>
                        </li>
                        <li>
                            <div class="small-icon">
                                <!-- <img src="@/assets/icons/maintenance2.jpg"> -->
                                <ImageItem class="article-item__image" :source="require(`../assets/icons/maintenance2.jpg`)" alt="Defect logs" />
                            </div>
                            <div class="details">Real time defect logs from any crew</div>
                            <div class="clear"></div>
                        </li>
                        <li>
                            <div class="small-icon">
                                <!-- <img src="@/assets/icons/boat-3.jpg"> -->
                                <ImageItem class="article-item__image" :source="require(`../assets/icons/boat-3.jpg`)" alt="Trip reports" />
                            </div>
                            <div class="details">Get full trip reports for every vessel trip</div>
                            <div class="clear"></div>
                        </li>
                        <li>
                            <div class="small-icon">
                                <!-- <img src="@/assets/icons/crew.jpg"> -->
                                <ImageItem class="article-item__image" :source="require(`../assets/icons/crew.jpg`)" alt="Set vessel masters" />
                            </div>
                            <div class="details">Set any crew member as a master to do logs from the app</div>
                            <div class="clear"></div>
                        </li>
                        <li>
                            <div class="small-icon">
                                <!-- <img src="@/assets/icons/checklist.jpg"> -->
                                <ImageItem class="article-item__image" :source="require(`../assets/icons/checklist.jpg`)" alt="Custom checklists" />
                            </div>
                            <div class="details">Create unlimited custom checklists for skippers</div>
                            <div class="clear"></div>
                        </li>
                        <li>
                            <div class="small-icon">
                                <!-- <img src="@/assets/icons/target.jpg"> -->
                                <ImageItem class="article-item__image" :source="require(`../assets/icons/target.jpg`)" alt="Document uploads" />
                            </div>
                            <div class="details">Upload important vessel documents to the cloud</div>
                            <div class="clear"></div>
                        </li>
                        <li>
                            <div class="small-icon">
                                <!-- <img src="@/assets/icons/wheel.jpg"> -->
                                <ImageItem class="article-item__image" :source="require(`../assets/icons/wheel.jpg`)" alt="Skipper access" />
                            </div>
                            <div class="details">Skippers can see which crew are due for an induction</div>
                            <div class="clear"></div>
                        </li>
                        <li>
                            <div class="small-icon">
                                <!-- <img src="@/assets/icons/signature.jpg"> -->
                                <ImageItem class="article-item__image" :source="require(`../assets/icons/signature.jpg`)" alt="Signed inductions" />
                            </div>
                            <div class="details">Vessel masters can add and induct new crew with signatures</div>
                            <div class="clear"></div>
                        </li>
                        <li>
                            <div class="small-icon">
                                <!-- <img src="@/assets/icons/target.jpg"> -->
                                <ImageItem class="article-item__image" :source="require(`../assets/icons/target.jpg`)" alt="Easy to print" />
                            </div>
                            <div class="details">Print out any vessel SMS as a PDF and send as an email</div>
                            <div class="clear"></div>
                        </li>
                        <li>
                            <div class="small-icon">
                                <!-- <img src="@/assets/icons/policies.jpg"> -->
                                <ImageItem class="article-item__image" :source="require(`../assets/icons/policies.jpg`)" alt="Real time updates" />
                            </div>
                            <div class="details">All changes to an SMS are updated in real time to crew and skippers</div>
                            <div class="clear"></div>
                        </li>
                        <li>
                            <div class="small-icon">
                                <!-- <img src="@/assets/icons/signature.jpg"> -->
                                <ImageItem class="article-item__image" :source="require(`../assets/icons/signature.jpg`)" alt="Self Inductions" />
                            </div>
                            <div class="details">NEW! Crew can do a signed Self Induction of your vessel SMS from anywhere!</div>
                            <div class="clear"></div>
                        </li>
                        <li>
                            <div class="small-icon">
                                <!-- <img src="@/assets/icons/language.jpg"> -->
                                <ImageItem class="article-item__image" :source="require(`../assets/icons/language.jpg`)" alt="Real-time messages" />
                            </div>
                            <div class="details">NEW! Send real time messages to your crew with read receipts and preset replies!</div>
                            <div class="clear"></div>
                        </li>
                    </ul>

                    <div class="buttons centered">
                        <span v-if="userID == ''" href="https://app.offshoresms.com.au/sign-up" class="button is-medium is-success" @click="trackLink('sign-up', 'signup')"
                            >Start using OffshoreSMS now</span
                        >
                        <span v-else href="https://app.offshoresms.com.au/sign-in" class="button is-medium is-success" @click="trackLink('sign-in', 'signin')">Go to My Dashboard</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="new-home-section">
            <div class="section-content">
                <hr />
            </div>
        </div>

        <div class="new-home-section">
            <div class="section-content">
                <div class="download centered">
                    <a href="https://apps.apple.com/us/app/offshoresms/id1526656158" target="_blank" rel="noreferrer">
                        <ImageItem class="article-item__image" :source="require(`../assets/img/app-store.png`)" alt="OffshoreSMS app on the Apple App Store" />
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=offshoresms.appdev.nybble.com.smsapp" target="_blank" rel="noreferrer">
                        <ImageItem class="article-item__image" :source="require(`../assets/img/play-store.png`)" alt="OffshoreSMS app on the Google Play Store" />
                    </a>
                </div>
            </div>
        </div>

        <div class="spacer s50"></div>

        <div class="new-home-section footer">
            <div class="section-content">
                <div class="">
                    <ul class="footer-links">
                        <li>
                            <router-link class="simple-link" to="feedback"><font-awesome-icon icon="phone" /> &nbsp; Get in touch</router-link>
                        </li>
                        <li>
                            <router-link class="simple-link" to="plan-fleet"><font-awesome-icon icon="star" /> &nbsp; Fleet Dashboard</router-link>
                        </li>
                        <li>
                            <router-link class="simple-link" to="faqs"><font-awesome-icon icon="info-circle" /> &nbsp; Read our FAQs</router-link>
                        </li>
                    </ul>
                </div>
                <div class="">
                    <ul class="footer-links">
                        <li>
                            <router-link class="simple-link" to="about-us"><font-awesome-icon icon="users" /> &nbsp; About Us</router-link>
                        </li>
                        <li>
                            <router-link class="simple-link" to="pricing"><font-awesome-icon icon="money-bill-alt" /> &nbsp; View our pricing</router-link>
                        </li>
                        <li>
                            <router-link class="simple-link" to="privacy-policy"><font-awesome-icon icon="lock" /> &nbsp; Privacy Policy</router-link>
                        </li>
                    </ul>
                </div>
                <div class="clear"></div>
                <div class="spacer s50"></div>
                <div class="centered">
                    Copyright &copy; OffshoreSMS Pty Ltd 2023 - All Rights Reserved.
                </div>
                <div class="clear"></div>
            </div>
        </div>

        <quick-enquiry v-if="openEnquiry" @closed="close()" />

        <simple-modal :title="selectedService.title" :fullWidth="true" :theme="selectedService.theme" @closed="close()" v-if="showServiceInfo">
            <table class="table is-fullwidth is-striped">
                <tbody>
                    <tr>
                        <th>Service</th>
                        <td class="green bold">{{ selectedService.title }}</td>
                    </tr>
                    <tr>
                        <th>Description</th>
                        <td>{{ selectedService.description }}</td>
                    </tr>
                    <tr>
                        <th>Price</th>
                        <td class="green bold">{{ selectedService.priceInfo }}</td>
                    </tr>
                    <tr>
                        <th>Details</th>
                        <td>
                            <ul class="details">
                                <li v-for="(item, index) in selectedService.details" :key="index"><font-awesome-icon icon="check-square" /> &nbsp;{{ item }}</li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <th>Outcomes</th>
                        <td>
                            <ul class="details">
                                <li v-for="(item, index) in selectedService.outcomes" :key="index"><font-awesome-icon icon="check" /> &nbsp;{{ item }}</li>
                            </ul>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p>
                For more information please call OffshoreSMS on <a href="tel:+61-447-139-382">0447 139 382</a> or send us an email at
                <a :href="'mailto:help@offshoresms.com.au?subject=Service enquiry - ' + selectedService.title">help@offshoresms.com.au</a>.
            </p>
            <div class="spacer s20"></div>
            <div class="buttons"></div>
        </simple-modal>

        <div class="buttons floating">
            <button class="button is-medium is-primary" @click="openEnquiry = true"><font-awesome-icon icon="comments" /> &nbsp; Quick Enquiry</button>
        </div>
    </div>
</template>

<script>
import { db } from '../main.js';
import { store } from '../store/store';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

import { Carousel, Slide } from 'vue-carousel';
import ImageItem from '../reusables/ImageItem.vue';
import NProgress from 'nprogress';
import QuickEnquiry from '../reusables/QuickEnquiry.vue';
const SimpleModal = () => import('../reusables/SimpleModal.vue');

export default {
    beforeRouteEnter(to, from, next) {
        next(vm => {
            let user = firebase.auth().currentUser;
            let firstLoad = from.name == null ? true : false;
        });
    },
    components: {
        Carousel,
        Slide,
        ImageItem,
        QuickEnquiry,
        SimpleModal
    },
    data: function() {
        return {
            userID: '',
            demoActivities: [],
            randomNumber: 0,
            randomActivity: '',
            randomActivities: [],
            newSignup: {
                email: ''
            },
            showField: false,
            savingLead: false,
            thankYou: '',
            reg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
            showHowItWorks: false,
            worksTab: 1,
            openEnquiry: false,
            selectedService: '',
            showServiceInfo: false,
            first: ['dropinSMS', 'assistedSetup', 'premiumSetup'],
            second: ['onlineTraining', 'onvesselAudits', 'virtualFleet'],
            loading: true,
            allServices: []
        };
    },
    mounted() {
        let user = firebase.auth().currentUser;
        if (user != null) this.userID = user.uid;
        this.loadServiceData();
        this.loadActivities();
    },
    computed: {
        emailValid() {
            return this.validateEmail();
        },
        phoneNumberValid() {
            // return this.smsEnquiry.phoneNumber.length > 10 && this.smsEnquiry.phoneNumber.slice(0,2) == '04';
            return this.smsEnquiry.phoneNumber.length > 6;
        },
        firstServices() {
            return this.allServices.filter(m => this.first.includes(m.slug));
        },
        secondServices() {
            return this.allServices.filter(m => this.second.includes(m.slug));
        }
    },

    methods: {
        onSlideChange() {
            console.log('slide change');
        },

        isNumber: function($evt) {
            $evt = $evt ? $evt : window.event;
            var charCode = $evt.which ? $evt.which : $evt.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
                $evt.preventDefault();
            } else {
                return true;
            }
        },

        updateCode({ name, iso2, dialCode }) {
            // console.log(name, iso2, dialCode);
            this.areaCode = dialCode.toString();
        },

        close() {
            this.openEnquiry = false;
            this.selectedService = '';
            this.showServiceInfo = false;
        },

        validateEmail() {
            return this.newSignup.email == '' ? false : this.reg.test(this.newSignup.email) ? true : false;
        },

        learnMore(service) {
            this.selectedService = service;
            this.showServiceInfo = true;
        },

        loadServiceData() {
            NProgress.start();
            db.collection('appInfo')
                .doc('services')
                .collection('offerings')
                .orderBy('index', 'asc')
                .get()
                .then(snap => {
                    snap.forEach(doc => {
                        let archived = doc.data().archived ? doc.data().archived : false;
                        if (!archived) this.allServices.push(doc.data());
                    });
                })
                .then(() => {
                    this.loading = false;
                    NProgress.done();
                })
                .catch(error => {
                    this.loading = false;
                    console.error('Error getting services:', error);
                    NProgress.done();
                });
        },

        saveLead() {
            let self = this;
            NProgress.start();
            this.thankYou = 'Saving email..';
            this.savingLead = true;
            console.log(this.newSignup.email);

            var saveNewLead = firebase.functions().httpsCallable('saveNewLead');
            saveNewLead({
                fullName: '',
                emailAddress: self.newSignup.email
            });
            let addRevision = firebase.functions().httpsCallable('addRevision');
            addRevision({
                userID: '-',
                userName: 'A visitor',
                description: 'A new lead has joined the OffshoreSMS newsletter (' + self.newSignup.email + ')'
            });

            setTimeout(function() {
                self.showField = false;
                self.newSignup.email = '';
                self.thankYou = 'Thanks! We promise to only send you the good stuff!';
                NProgress.done();
            }, 1000);
        },

        loadActivities() {
            let self = this;
            db.collection('demoActivities')
                .get()
                .then(snap => {
                    self.demoActivities = [];
                    snap.forEach(doc => {
                        self.demoActivities.push({
                            id: doc.id,
                            title: doc.data().title
                        });
                    });
                })
                .then(() => {
                    let i = 0;
                    for (i = 0; i < 5; i++) {
                        self.randomActivities.push(self.demoActivities[i]);
                    }
                });
        },

        trackLink(page, type) {
            console.log(page, type);
            NProgress.start();
            let addRevision = firebase.functions().httpsCallable('addRevision');
            addRevision({
                userID: '-',
                userName: '-',
                description: 'A user has clicked through to the app.offshoresms ' + type + ' page.'
            });
            window.location.href = 'https://app.offshoresms.com.au/' + page;
        },

        addNextActivity() {
            var randomItem = this.demoActivities[Math.floor(Math.random() * this.demoActivities.length)];
            this.randomActivities.unshift(randomItem);
        }
    }
};
</script>

<style lang="scss" scoped>
.tab-wrapper {
    display: flex;
    justify-content: center;
    width: 90%;
    margin: 0 auto;
    .round-tab {
        display: flex;
        min-width: 350px;
        min-height: 100px;
        border-radius: 10px;
        overflow: hidden;
        justify-content: center;
        align-items: center;
        background: #f3f3f3;
        flex-direction: column;
        padding: 1.2rem;
        margin: 0 1rem;
        cursor: pointer;
        span {
            &.icon {
                margin-right: 0;
                font-size: 2rem;
                display: block;
                height: auto;
                width: auto;
            }
            &.text {
                font-size: 1.25rem;
            }
        }
        &.selected {
            background: #00d1b2;
            color: #fff;
            &:after {
            }
        }
    }
}
.table {
    tbody {
        tr {
            td {
            }
        }
    }
}
ul.details {
    li {
        margin-bottom: 6px;
        text-indent: -23px;
        margin-left: 23px;
    }
}
.tab-content-wrapper {
    background: #f3f3f3;
    display: flex;
    position: relative;
    padding: 4rem;
    width: 90%;
    max-width: 1200px;
    margin: 4rem auto;
    border-radius: 12px;
    &:before {
        position: absolute;
        content: '';
        width: 0;
        height: 0;
        top: -40px;
        left: 31%;
        border-style: solid;
        border-width: 0 40px 40px 40px;
        border-color: transparent transparent #f3f3f3 transparent;
    }
    &.two {
        &:before {
            right: 31%;
            left: auto;
        }
    }
    .tc-image {
        width: 50%;
        img {
            max-width: 500px;
            width: 90%;
        }
    }
    .tc-content {
        width: 50%;
        h4 {
            font-family: 'Montserrat', sans-serif;
            font-weight: bold;
            margin-bottom: 1.5rem;
        }
        .home-steps-list {
            margin-bottom: 2.5rem;
            .step-item {
                display: flex;
                margin-bottom: 20px;
                .counter {
                    background-color: #7d2ae8;
                    border-radius: 50%;
                    width: 27px;
                    height: 27px;
                    display: flex;
                    justify-content: center;
                    color: #fff;
                    align-items: center;
                    /* font-weight: bold; */
                    font-size: 0.9rem;
                    margin-right: 8px;
                    margin-top: 3px;
                    span {
                        width: 30px;
                        text-align: center;
                    }
                }
                .details {
                    line-height: 1.7rem;
                    font-size: 1.05rem;
                    text-align: left;
                }
            }
        }
    }
}

.image__item {
    max-width: 90%;
}
.hero-intro {
    width: 90%;
    margin: 3rem auto 1rem;
    text-align: center;
    h1 {
        font-family: 'Roboto Slab', serif;
        font-size: 2rem;
        line-height: 2.5rem;
        color: #19b6eb;
        svg {
            font-size: 1.5rem;
            vertical-align: baseline;
        }
    }
    h2 {
        font-size: 1.2rem;
        line-height: 1.6rem;
        margin: 1rem auto 2rem;
        color: #666;
    }
}
.new-home-section {
    width: 100%;
    margin: 0;
    &.hero {
        // background: #2562a4;
        // background-image: linear-gradient(#3e76b3, #2562a4);
        background: url('../assets/img/hero-bg5.jpg') no-repeat center center;
        height: auto;
        // background: #f7f7f7;
        // margin-top: -20px;
        position: relative;
        background-size: cover;
        // margin-bottom: 2rem;
        .section-content {
            margin: 0;
            padding: 0;
            max-width: none;
            h2,
            h5 {
                color: #fff;
            }
            img {
                max-width: 400px;
            }
            .left-side {
                float: left;
                width: 50%;
                padding: 5rem 3rem;
                height: 100%;
                // border-top-right-radius: 13% 80%;
                border-bottom-right-radius: 13% 80%;
                // background: #1d4ace;
                // background-image: linear-gradient(#06145a, #11598c);
                // background-image: linear-gradient(#103469, #1d83ca);
                // height: 550px;
                padding: 5rem 3rem;
                height: 650px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                &.text-side {
                    .text-wrapper {
                        background: rgba(0, 0, 0, 0.7);
                        padding: 2rem;
                        border-radius: 8px;
                        h2 {
                        }
                        h3 {
                        }
                        .buttons {
                            max-width: none;
                            margin-left: 0;
                        }
                    }
                    h3 {
                        &.price {
                            color: #fff;
                            font-size: 1.7rem;
                            margin-top: 1rem;
                            &.is-warning {
                                color: #feff07;
                            }
                        }
                    }
                }
            }
            .right-side {
                float: right;
                width: 50%;
                padding: 5rem 3rem;
                height: 650px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                img {
                    max-width: 400px;
                    width: 100%;
                }
                .image__wrapper {
                    max-width: 400px;
                    margin: 0 auto;
                    background: #fff;
                    border-radius: 50%;
                }
            }
            .VueCarousel {
                overflow: hidden;
                img {
                    max-width: 400px;
                }
            }
            // .slider-carousel > div {
            //   overflow: hidden !important;
            // }
        }
        .scroll-more {
            // width: 140px;
            margin: 2rem auto 0;
            text-align: center;
            // position: absolute;
            // bottom: 5px;
            // left: 50%;
            // transform: translateX(-70px);
            .scroll-text {
                color: #fff;
                font-size: 0.9rem;
            }
            .down-arrow {
                width: 42px;
                height: 42px;
                margin: 0.5rem auto;
                background: url('../assets/img/down-arrow.png') no-repeat center center;
                background-size: cover;
            }
        }
    }
    &.newsletter {
        padding: 3rem 1rem 2rem;
        h5 {
            color: dodgerblue;
            font-size: 1.2rem;
        }
        input {
            padding: 1.5rem 2rem;
            height: auto;
            border: 1px solid #dedede;
            border-radius: 3px;
            display: inline-block;
            margin: 15px auto 20px;
            font-size: 1.2rem;
            width: 100%;
            max-width: 400px;
            &:focus-visible {
                outline: none;
                border: 1px solid limegreen;
            }
        }
    }
    &.grey-bar {
        margin: 0;
        padding: 2.2rem 0 1.8rem;
        background: #1c82c9;
        margin-top: 0;
    }
    .section-content {
        margin: 6rem auto 4rem;
        padding: 0 2rem;
        width: 100%;
        max-width: 1200px;
        h2 {
            font-family: 'Roboto Slab', serif;
            color: #2562a4;
            font-size: 2.2rem;
            line-height: 2.5rem;
            margin-bottom: 1.5rem;
        }
        h3 {
            font-family: 'Roboto Slab', serif;
            color: #2562a4;
            font-size: 2rem;
            line-height: 2.3rem;
            margin-bottom: 1.5rem;
            &.green {
                color: limegreen;
            }
            &.is-primary {
                color: #00d1b2;
            }
        }
        h5 {
            color: #2562a4;
            font-size: 1.2rem;
            &.bold {
                font-weight: bold;
                margin-top: 2rem;
            }
        }
        .left-side {
            float: left;
            width: 50%;
            padding-top: 3rem;
            img {
                max-width: none;
                width: 100%;
            }
            // &.text-side {
            //   padding-top: 3rem;
            // }
        }
        .right-side {
            float: right;
            width: 50%;
            img {
                max-width: none;
                width: 100%;
            }
            &.text-side {
                padding-top: 3rem;
                padding-left: 2rem;
            }
        }
        .thirds {
            width: 33%;
            float: left;
            text-align: center;
        }
        .simple-link {
            color: #2562a4;
            transition: 0.3s;
            &:hover {
                color: #37cda8;
            }
        }
        .footer-links {
            display: flex;
            justify-content: center;
            li {
                margin-bottom: 0.6rem;
                flex-basis: 33%;
                text-align: center;
            }
        }
        .cloud {
            font-size: 5rem;
            color: #f947a7;
        }
    }
    &.tight {
        .section-content {
            margin-top: 2rem;
        }
    }
}

.full-width-image {
    height: 160px;
    width: 100%;
    position: relative;
    &.section1 {
        background: url('../assets/img/offshore-sms-boat-tied-2.jpg') no-repeat center center;
        background-size: cover;
        .hero-content {
            position: absolute;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            top: 35%;
            left: 0%;
            width: 90%;
            margin: 0 20px;
            font-size: 1.8rem;
            .hero-icon {
                // padding-top: 3px;
                margin-right: 10px;
            }
            .hero-text {
                line-height: 2rem;
            }
        }
    }
}

.icon-list {
    width: 100%;
    margin: 3rem auto;
    max-width: 600px;
    li {
        margin-bottom: 2rem;
        width: 100%;
        .small-icon {
            float: left;
            width: 80px;
            img {
                width: 100%;
            }
        }
        .details {
            float: left;
            width: calc(100% - 120px);
            font-family: 'Roboto Slab', serif;
            // color: #ff7878;
            color: #37cda8;
            font-size: 1.4rem;
            line-height: 1.8rem;
            text-align: left;
            margin-left: 10px;
            padding-top: 1rem;
        }
    }
}

.imac-wrapper {
    position: relative;
    height: 800px;
    img {
        max-width: none;
    }
    .imac-bg {
        position: absolute;
        top: 0;
        left: 0;
        max-width: none;
        width: 100%;
        z-index: 999;
    }
    .screen-content {
    }
    .screen-bg {
        // background: red;
        background: url('../assets/img/fleet-bg.jpg') no-repeat top left;
        background-size: inherit;
        position: absolute;
        // top: 55px;
        // left: 160px;
        // width: 780px;
        // height: 440px;
        top: 55px;
        left: 15%;
        width: 68%;
        height: 58%;
        max-height: 340px;
        z-index: 998;
        margin-top: 5px;
        margin-left: 5px;
        overflow: hidden;
        .screen-content {
            position: absolute;
            left: 45px;
            top: 50px;
            width: 90%;
            text-align: left;
            h3 {
                text-transform: uppercase;
                font-weight: bold;
                padding-bottom: 10px;
                border-bottom: 1px solid #ddd;
                display: block;
                margin-bottom: 1rem;
                margin-top: 0;
                color: cadetblue;
                font-size: 1rem;
                svg {
                    vertical-align: sub;
                    font-size: 1.2rem;
                }
            }
            ul.revisions {
                list-style: disc inside;
                margin-left: 8px;
                li {
                    text-indent: -23px;
                    margin-left: 20px;
                    margin-bottom: 10px;
                    font-size: 0.9rem;
                    color: dodgerblue;
                    &.current {
                        color: #3273dc;
                    }
                }
            }
        }
    }
}
.static-image {
    display: none;
}

.modal {
    &.modal-full-screen {
        .modal-card {
            max-height: 80%;
            max-height: 80vh;
            max-width: 1000px;
        }
    }
}

.download {
    margin-bottom: 2rem;
    a {
        display: inline-block;
        max-width: 270px;
        margin: 0 5px;
    }
    &.mini {
        a {
            max-width: 140px;
        }
    }
}

.counter-wrap {
    margin-bottom: 2rem;
}

img.partners {
    width: 90%;
    max-width: 400px;
}

.simple-link {
    color: #fff;
    &:visited {
        color: #fff;
    }
}

.buttons {
    display: block;
    max-width: 95%;
    margin: 0 auto;
    .button {
        display: inline-block;
        height: auto;
        line-height: 1.5rem;
        padding: 20px 25px;
        white-space: normal;
        min-width: 145px;
        &.is-skip {
            border-color: #ff7878;
        }
    }
}

.pricing-table {
    .pricing-plan {
        .plan-price {
            .price-rate {
                width: 60px;
                display: inline-block;
                margin-left: 5px;
                line-height: 14px;
                text-align: left;
            }
        }
    }
}

.pricing-wrapper {
    width: 100%;
    // max-width: 900px;
    margin: 0 auto;
    .pricing-table {
        // width: 44%;
        width: 100%;
        float: left;
        .pricing-plan {
            // border: 2px solid #ff3860;
            border-radius: 8px;
            box-shadow: rgba(122, 122, 122, 0.2) 0 8px 12px 0;
            margin-bottom: 2rem;
            .plan-header {
                padding-bottom: 1rem;
                color: #fff;
                background: #444;
            }
            .plan-icon {
                font-size: 4rem;
            }
            .plan-price {
                padding-top: 0;
                .price-rate {
                    width: 60px;
                    display: inline-block;
                    margin-left: 5px;
                    line-height: 14px;
                    text-align: left;
                    color: #444;
                }
            }
            .plan-footer {
                a {
                    padding: 1.5rem;
                }
            }
            &.is-danger {
                .plan-header {
                    background: #f14668;
                }
                .plan-icon,
                .plan-price,
                .plan-price-amount {
                    color: #f14668;
                }
                .plan-footer {
                    .button {
                        background-color: #f14668;
                        border-color: #f14668;
                    }
                }
            }
            &.is-dark {
                .plan-header {
                    background: #363636;
                }
                .plan-icon,
                .plan-price,
                .plan-price-amount {
                    color: #363636;
                }
                .plan-footer {
                    .button {
                        background-color: #363636;
                        border-color: #363636;
                    }
                }
            }
            &.is-primary {
                .plan-header {
                    background: #00d1b2;
                }
                .plan-icon,
                .plan-price,
                .plan-price-amount {
                    color: #00d1b2;
                }
                .plan-footer {
                    .button {
                        background-color: #00d1b2;
                        border-color: #00d1b2;
                    }
                }
            }
            &.is-warning {
                .plan-header {
                    background: #fbd15d;
                }
                .plan-icon,
                .plan-price,
                .plan-price-amount {
                    color: #fbd15d;
                }
                .plan-footer {
                    .button {
                        background-color: #fbd15d;
                        border-color: #fbd15d;
                    }
                }
            }
            &.is-skip {
                .plan-header {
                    background: #ff7878;
                }
                .plan-icon,
                .plan-price,
                .plan-price-amount {
                    color: #ff7878;
                }
                .plan-footer {
                    .button {
                        background-color: #ff7878;
                        border-color: #ff7878;
                        color: #fff;
                    }
                }
            }
            &.is-gold {
                .plan-header {
                    background: #eebe26;
                }
                .plan-icon,
                .plan-price,
                .plan-price-amount {
                    color: #eebe26;
                }
                .plan-footer {
                    .button {
                        border-color: #eebe26;
                        background-color: #eebe26;
                        color: #fff;
                    }
                }
            }
            &.is-blue {
                .plan-header {
                    background: #2c7dcc;
                }
                .plan-icon,
                .plan-price,
                .plan-price-amount {
                    color: #2c7dcc;
                }
                .plan-footer {
                    .button {
                        border-color: #2c7dcc;
                        background-color: #2c7dcc;
                        color: #fff;
                    }
                }
            }
            &.is-purple {
                .plan-header {
                    background: purple;
                }
                .plan-icon,
                .plan-price,
                .plan-price-amount {
                    color: purple;
                }
                .plan-footer {
                    .button {
                        border-color: purple;
                        background-color: purple;
                        color: #fff;
                    }
                }
            }
            &.is-green {
                .plan-header {
                    background: mediumseagreen;
                }
                .plan-icon,
                .plan-price,
                .plan-price-amount {
                    color: mediumseagreen;
                }
                .plan-footer {
                    .button {
                        background-color: mediumseagreen;
                        border-color: mediumseagreen;
                        color: #fff;
                    }
                }
            }
            &.is-hotpink {
                .plan-header {
                    background: #f947a7;
                }
                .plan-icon,
                .plan-price,
                .plan-price-amount {
                    color: #f947a7;
                }
                .plan-footer {
                    .button {
                        background-color: #f947a7;
                        border-color: #f947a7;
                    }
                }
            }
        }
        .heading {
            text-align: center;
            margin: 0 auto 1rem;
        }
        &.squashed {
            padding: 0;
            width: 100%;
            .pricing-plan {
                width: 100%;
                display: block;
                margin-bottom: 10px;
                border: 1px solid whitesmoke;
                .plan-header {
                    width: 42%;
                    float: left;
                    height: auto;
                    padding: 0.75em;
                    padding-top: 1.3rem;
                    background: #fff;
                    color: #333;
                }
                .plan-price {
                    width: 50%;
                    float: left;
                    text-align: left;
                }
                .plan-footer {
                    a {
                        // background: #d2cdcd;
                        padding: 5px 12px;
                    }
                }
            }
        }
    }
    .other-plans {
        width: 56%;
        float: right;
        padding: 0 2rem;
        text-align: center;
        img {
            max-width: 100%;
            margin: 2rem 0;
        }
    }
    &.fleet {
        width: 95%;
        margin: 0 auto 3rem;
        max-width: 1140px;
    }
}

@media only screen and (max-width: 959px) {
    .imac-wrapper {
        height: 600px;
        .screen-bg {
            top: 40px;
            max-height: 270px;
        }
    }
    .new-home-section.hero {
        .section-content {
            .left-side {
                // width: 100%;
                // float: none;
                // margin-bottom: 2rem;
                padding: 3rem 2rem;
            }
            .right-side {
                // width: 100%;
                // float: none;
                // margin-bottom: 2rem;
                &.text-side {
                    padding-top: 0;
                    padding-left: 0;
                    // text-align: center;
                }
            }
        }
    }
}

@media only screen and (max-width: 859px) {
    .new-home-section.hero {
        .section-content {
            .left-side {
                width: 100%;
                height: auto;
                text-align: center;
                border-radius: 0;
                padding: 2rem 1rem;
                height: 450px;
            }
            .right-side {
                width: 100%;
                height: auto;
                text-align: center;
                padding: 1rem;
            }
        }
    }
    .new-home-section {
        .section-content {
            .left-side {
                width: 100%;
                height: auto;
                text-align: center;
                border-radius: 0;
                padding: 2rem 0;
            }
            .right-side {
                width: 100%;
                height: auto;
                text-align: center;
                padding: 1rem 0;
                &.text-side {
                    padding: 1rem 0;
                }
            }
        }
    }
}
// .modal-card-body {
//     &.shrink {
//         flex-grow: 0;
//     }
// }
// .enquiry-wrapper {
//     .step-1 {
//     }
//     .step-2 {
//         .mobile-wrapper {
//             display: flex;
//             .code {
//                 width: 75px;
//                 margin-right: 6px;
//                 .vue-country-select {
//                     height: 64px;
//                     width: 100%;
//                     .dropdown {
//                         height: 100%;
//                     }
//                 }
//             }
//             .mobile {
//                 flex-grow: 1;
//             }
//         }
//     }
//     .icon-label {
//         display: flex;
//         margin-bottom: 8px;
//         color: cadetblue;
//         text-transform: uppercase;
//         font-weight: bold;
//         align-items: center;
//         .icon-img {
//             margin-right: 6px;
//             width: 30px;
//             display: flex;
//             align-items: center;
//             img {
//                 width: 100%;
//             }
//         }
//         .label-text {
//             flex-grow: 1;
//         }
//     }
// }
// .message-sent {
//     display: flex;
//     margin-bottom: 30px;
//     color: mediumseagreen;
//     text-transform: uppercase;
//     font-weight: bold;
//     align-items: center;
//     .icon-img {
//         margin-right: 6px;
//         width: 50px;
//         display: flex;
//         align-items: center;
//         img {
//             width: 100%;
//         }
//     }
//     .response {
//         flex-grow: 1;
//     }
// }

@media only screen and (max-width: 767px) {
    .home-wrapper {
        position: relative;
        z-index: 1100;
        background: #fff;
    }
    .imac-wrapper {
        display: none;
    }
    .test-activity {
        display: none;
    }
    .static-image {
        display: block;
        img {
            max-width: none;
            width: 100%;
        }
    }
    .new-home-section {
        .section-content {
            padding: 0 1rem;
            .thirds {
                width: 100%;
                float: none;
                text-align: center;
                margin-bottom: 2rem;
            }
            .footer-links {
                flex-direction: column;
                li {
                    flex-basis: 100%;
                }
            }
        }
    }
    .download {
        a {
            max-width: 170px;
        }
    }
    .tab-wrapper {
        .round-tab {
            min-width: auto;
            padding: 1rem 2rem;
            font-size: 1.1rem;
            line-height: 1.3rem;
            span {
                &.icon {
                    font-size: 1.5rem;
                    margin-bottom: 7px;
                }
                &.text {
                    font-size: 1.1rem;
                }
            }
        }
    }
    .tab-content-wrapper {
        padding: 3rem 2rem;
        flex-direction: column;
        &:before {
            top: -40px;
            left: 22%;
        }
        &.two {
            &:before {
                right: 20%;
                left: auto;
            }
        }
        .tc-image {
            width: 100%;
            order: 2;
        }
        .tc-content {
            width: 100%;
        }
    }
    .full-width-image {
        height: 300px;
        &.section1 {
            .hero-content {
                flex-direction: column;
                align-items: center;
                text-align: center;
                position: relative;
                // left: auto;
                // .hero-text {
                //     margin: 0 20px;
                // }
            }
        }
    }
}

@media only screen and (max-width: 479px) {
    .message-sent {
        flex-direction: column;
        .icon-img {
            margin-bottom: 15px;
        }
    }
    .buttons {
        .button {
            display: block;
            height: auto;
            font-size: 1.1rem;
            line-height: 1.1rem;
            padding: 12px 6px;
            margin-right: 2px !important;
        }
    }

    .new-home-section.hero {
        .section-content {
            .left-side {
                height: auto;
            }
        }
    }

    .icon-list {
        li {
            margin-bottom: 3rem;
            .small-icon {
                float: none;
                margin: 0 auto;
            }
            .details {
                float: none;
                width: 100%;
                margin-left: 0;
                padding: 0;
                text-align: center;
            }
        }
    }
    .download {
        a {
            max-width: 150px;
        }
    }
    .tab-wrapper {
        width: 95%;
        // flex-direction: column;
        .round-tab {
            min-width: auto;
            padding: 10px 12px;
            font-size: 1rem;
            line-height: 1.1rem;
            margin: 0 5px;
            span {
                &.icon {
                    font-size: 1.2rem;
                    margin-bottom: 7px;
                }
                &.text {
                    font-size: 1rem;
                }
            }
        }
    }
    .tab-content-wrapper {
        padding: 3rem 1.5rem;
        flex-direction: column;
        &:before {
            left: 10%;
        }
        &.two {
            &:before {
                right: 10%;
                left: auto;
            }
        }
    }
}
</style>
